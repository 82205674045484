import React from "react";

import {
  FormControlLabel,
  FormControlLabelProps,
  alpha,
  styled,
} from "@mui/material";

import { fonts } from "assets/typography";
import CardCustom from "components/cardCustom";
import { MainContainer } from "styled";
import { theme } from "themes";
import type { FUNNEL_METHOD, FUNNEL_TYPE } from "types";

type FunnelLayoutProps = {
  title: string;
  enumActions?: FUNNEL_METHOD[] | FUNNEL_TYPE[];
  switchProps?: FormControlLabelProps;
};

export const Title = styled("h3")`
  ${({ theme }) => `font-size: ${theme.spacing(3)};`};
  font-family: ${fonts.nouvelRBold};
  color: ${theme.palette.common.white};
  padding: ${theme.spacing(1, 2, 2, 2)};
`;

export const StyledCard = styled("div")`
  display: grid;
  line-height: 0.95;
  height: auto;
  max-height: 100%;
  max-width: ${theme.spacing(100)};
  border-width: ${theme.spacing(0.25)};
  border-style: solid;
  border-radius: ${theme.spacing(0.5)};
  padding: ${theme.spacing(2)};
  margin: ${theme.spacing(0, 2, 4, 2)};
  border-color: ${theme.palette.secondary.main};
  background-color: ${alpha(theme.palette.secondary.light, 0.95)};
`;

const FunnelLayout: React.FunctionComponent<FunnelLayoutProps> = ({
  title,
  enumActions,
  switchProps,
}) => (
  <MainContainer>
    <Title sx={{ textAlign: "center" }}>{title}</Title>
    <StyledCard>
      {enumActions && (
        <CardCustom enumActions={enumActions}>
          {switchProps && (
            <FormControlLabel
              {...switchProps}
              sx={{
                marginLeft: 0,
              }}
            />
          )}
        </CardCustom>
      )}
    </StyledCard>
  </MainContainer>
);

export default FunnelLayout;
