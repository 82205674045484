import type { FC } from "react";

import { Box, Paper } from "@mui/material";

import { colors } from "assets/styles";

interface Props {
  children: (JSX.Element | undefined | number)[];
}

const CustomPaper: FC<Props & { className?: string }> = ({
  children,
  className,
}) => (
  <Paper
    component={Box}
    elevation={5}
    bgcolor={colors.white}
    display="flex"
    className={className}
  >
    {children}
  </Paper>
);

export default CustomPaper;
