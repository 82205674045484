import React, { type FC } from "react";
import { FormattedMessage } from "react-intl";

import HomeIcon from "@mui/icons-material/HomeOutlined";
import { Box, Typography } from "@mui/material";

import { ContactButton } from "./index";
import { SubPaper } from "components/paper";
import type { QuotationDTO } from "types";

interface Props {
  quotation: QuotationDTO | undefined;
}

const ContactBlock: FC<Props> = ({ quotation }) => (
  <SubPaper>
    <Typography variant="h5" fontWeight="bold" textAlign="center">
      <FormattedMessage id="funnel.estimation.contact" />
    </Typography>
    <Box display="flex">
      <HomeIcon />
      <Typography pl={1}>{quotation?.dealerName.toUpperCase()}</Typography>
    </Box>
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <ContactButton
        message={<FormattedMessage id="funnel.result.contact" />}
        primary
      />
      <ContactButton
        message={<FormattedMessage id="funnel.result.meeting" />}
      />
      <ContactButton
        message={<FormattedMessage id="funnel.result.callback" />}
      />
    </Box>
  </SubPaper>
);

export default ContactBlock;
