import React, { type FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Box, Typography } from "@mui/material";

import { SubPaper } from "components/paper";
import dayjs from "dayjs";
import { useAppSelector } from "store/hooks";
import type { QuotationDTO } from "types";
import { VALIDITY_DATE_FORMAT } from "utils";
import { formatPrice } from "utils/function.utils";

interface Props {
  quotation: QuotationDTO | undefined;
}

const EstimationBlock: FC<Props> = ({ quotation }) => {
  const intl = useIntl();

  const { formInit } = useAppSelector((state) => state.funnel);

  return (
    <SubPaper>
      <Typography variant="h5" fontWeight="bold" textAlign="center">
        {quotation?.quotationPrice ? (
          <FormattedMessage id="funnel.estimation.price" />
        ) : (
          <FormattedMessage id="funnel.estimation.noprice" />
        )}
      </Typography>
      {quotation?.quotationPrice && formInit && (
        <Box display="flex" justifyContent="center">
          <Typography variant="h3" fontWeight="bold">
            {formatPrice(intl, quotation.quotationPrice, formInit.currency)}
          </Typography>
          <Typography>
            <FormattedMessage id={"funnel.result.taxes"} />*
          </Typography>
        </Box>
      )}
      <Typography textAlign="justify" variant="body2">
        {quotation?.quotationPrice ? (
          quotation?.quotationValidity ? (
            <FormattedMessage
              id="funnel.estimation.validity"
              values={{
                date: dayjs(quotation.quotationValidity)
                  .format(VALIDITY_DATE_FORMAT)
                  .toString(),
              }}
            />
          ) : (
            <FormattedMessage id="funnel.estimation.novalidity" />
          )
        ) : (
          <FormattedMessage id="funnel.estimation.solution" />
        )}
      </Typography>
    </SubPaper>
  );
};

export default EstimationBlock;
