import React from "react";
import { FormattedMessage } from "react-intl";

import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  alpha,
  styled,
  useTheme,
} from "@mui/material";

import {
  ContactBlock,
  EstimationBlock,
  StyledTitle,
  VehicleBlock,
} from "components/quotation";
import { OfferArea } from "components/quotation";
import Spinner from "components/spinner";
import { useAppSelector } from "store/hooks";
import { MainContainer } from "styled";

const Quotation: React.FunctionComponent = () => {
  const theme = useTheme();

  const { state, quotationResult } = useAppSelector((state) => state.funnel);

  const searchName = state?.find((answer) => answer.id === "1")?.answers?.[0];
  const vehicleName = searchName && "value" in searchName && searchName?.value;

  const StyledContainer = styled(Container)`
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${alpha(theme.palette.secondary.light, 0.85)};
  `;

  return (
    <MainContainer>
      {!quotationResult ? (
        <Spinner fullHeight />
      ) : (
        <StyledContainer sx={{ pt: 2 }}>
          <Box
            display={"flex"}
            flexDirection={{ md: "row", xs: "column" }}
            alignItems={{ md: "center", xs: "flex-start" }}
            sx={{ gap: { md: 3, xs: 0 } }}
          >
            <Typography variant="h4" fontWeight="bold" height={"100%"}>
              {quotationResult?.brand} {quotationResult?.modelId}
            </Typography>
            <Typography sx={{ mt: 1 }}>{vehicleName}</Typography>
          </Box>
          <StyledTitle
            children={
              <FormattedMessage
                id="funnel.estimation.number"
                values={{ number: quotationResult?.quotationId }}
              />
            }
          />
          <Grid container mb={4} spacing={2}>
            <Grid item lg={7} xs={12}>
              <VehicleBlock quotation={quotationResult} />
            </Grid>
            <Grid
              item
              lg={5}
              xs={12}
              direction="row"
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
              gap={2}
            >
              <Grid item height={"100%"}>
                <EstimationBlock quotation={quotationResult} />
              </Grid>
              <Grid item height={"100%"}>
                <ContactBlock quotation={quotationResult} />
              </Grid>
            </Grid>
          </Grid>
          {quotationResult?.promotions.length !== 0 && (
            <>
              <Divider />
              <StyledTitle
                children={<FormattedMessage id="funnel.result.offer" />}
              />
              <OfferArea quotation={quotationResult} />
            </>
          )}
        </StyledContainer>
      )}
    </MainContainer>
  );
};

export default Quotation;
