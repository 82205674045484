import { createSlice } from "@reduxjs/toolkit";

import { type SharedProps } from "notistack";
import type { Config, Configuration } from "types";
import { CONFIG_PROPERTIES } from "types/enums.types";
import { getRandomKey } from "utils/common.utils";

export type SnackbarProps = {
  severity: SharedProps["variant"];
  message: string;
  translation?: boolean;
  key?: number;
};

export interface CommonState {
  snackbars: SnackbarProps[];
  config?: Config;
}

const initialState: CommonState = {
  snackbars: [],
};

export const commonReducer = createSlice({
  name: "common",
  initialState,
  reducers: {
    addSnackbar: (state, { payload }: { payload: SnackbarProps }) => {
      state.snackbars.push({ ...payload, key: getRandomKey() });
    },
    removeSnackbar: (state, { payload }: { payload: number }) => {
      state.snackbars = state.snackbars.filter(({ key }) => key !== payload);
    },
    setConfiguration: (state, { payload }: { payload: Configuration }) => {
      state.config = {
        env: payload[CONFIG_PROPERTIES.ENV],
        apiBaseHostname: payload[CONFIG_PROPERTIES.API_BASE_HOSTNAME],
      };
    },
  },
});

export const { addSnackbar, removeSnackbar, setConfiguration } =
  commonReducer.actions;

export default commonReducer.reducer;
