import React, { type FC } from "react";
import type { Control, FieldValues, UseFormResetField } from "react-hook-form";

import { Box, Typography } from "@mui/material";

import { QuestionField } from "components/question";
import Spinner from "components/spinner";
import type { SectionDTO } from "types";

interface Props {
  section: SectionDTO;
  isLoading: boolean;
  control: Control<FieldValues, string>;
  resetField: UseFormResetField<FieldValues>;
}

const Section: FC<Props> = ({ section, isLoading, control, resetField }) => (
  <Box
    sx={{
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }}
    my={2}
    gap={1}
  >
    {isLoading ? (
      <Spinner fullHeight />
    ) : (
      <>
        <Typography variant="h4" fontWeight={700} mb={3}>
          {section.label}
        </Typography>
        {section.questions.map((question) => (
          <QuestionField
            key={question.id}
            question={question}
            section={section.questions}
            control={control}
            resetField={resetField}
          />
        ))}
      </>
    )}
  </Box>
);

export default Section;
