import {
  RENAULT_COOKIES_URL,
  RENAULT_FAQ_URL,
  RENAULT_LEGAL_INFORMATION_URL,
  RENAULT_MODELS_URL,
  RENAULT_WEBSITE_URL,
  RENAULT_YOUR_DATA_URL,
  RENEW_URL,
} from "./url.utils";
import type { FooterLinkModel } from "types";

export const LOCAL_STORAGE = {
  REDIRECT_URL: "redirectUrl",
  ERROR_CODE: "errorCode",
};

export const getRandomKey = () => new Date().getTime() + Math.random();

export const getErrorFromFetch = (error?: unknown): Error | undefined =>
  error && typeof error === "object" && "data" in error
    ? (error.data as Error)
    : undefined;

export const footerLinks: FooterLinkModel[] = [
  {
    ref: RENAULT_WEBSITE_URL,
    formattedMessageId: "footer.link.renault.website",
  },
  {
    ref: RENEW_URL,
    formattedMessageId: "footer.link.renew",
  },
  {
    ref: RENAULT_MODELS_URL,
    formattedMessageId: "footer.link.our.models",
  },
  {
    ref: RENAULT_FAQ_URL,
    formattedMessageId: "footer.link.faq",
  },
  {
    ref: RENAULT_YOUR_DATA_URL,
    formattedMessageId: "footer.link.your.data",
  },
  {
    ref: RENAULT_LEGAL_INFORMATION_URL,
    formattedMessageId: "footer.link.legal.information",
  },
  {
    ref: RENAULT_COOKIES_URL,
    formattedMessageId: "footer.link.cookies",
  },
];
