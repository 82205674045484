import React, { useEffect } from "react";

import { Container } from "@mui/material";

import Spinner from "components/spinner";
import { MainStepper } from "components/stepper";
import useCallBackend from "hooks/useCallBackend";
import { useAppSelector } from "store/hooks";

const FormLayout: React.FunctionComponent = () => {
  const { funnelType, funnelProfile, funnelMethod, state } = useAppSelector(
    (state) => state.funnel,
  );

  const { getQuestion, isLoading } = useCallBackend();

  useEffect(() => {
    if (!state) {
      getQuestion();
    }
  }, [funnelProfile, funnelMethod, funnelType]);

  return (
    <Container sx={{ mb: 5 }}>
      {isLoading ? <Spinner fullHeight /> : <MainStepper />}
    </Container>
  );
};

export default FormLayout;
