import React, { type FC } from "react";
import { Controller } from "react-hook-form";
import { useIntl } from "react-intl";

import TextField from "@mui/material/TextField";

import type { InputFieldProps } from "types";
import { fieldRules, questionDTOToInputProps } from "utils/field.utils";

const GenericTextInput: FC<InputFieldProps> = ({
  value,
  question,
  onChange,
  control,
}) => (
  <Controller
    name={question.id}
    control={control}
    rules={fieldRules(question, useIntl())}
    render={({ field, fieldState }) => (
      <TextField
        {...field}
        sx={{ width: "100%", mb: 0 }}
        label={question.fieldConfig?.placeholder}
        {...questionDTOToInputProps(question)}
        variant="outlined"
        value={value || ""}
        onChange={(e) => {
          field.onChange(e);
          onChange(e.target.value);
        }}
        error={!!fieldState.error}
        helperText={fieldState.error?.message}
      />
    )}
  />
);

export default GenericTextInput;
