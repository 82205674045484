import React, { type FC } from "react";
import { Controller } from "react-hook-form";
import { useIntl } from "react-intl";

import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  styled,
  useTheme,
} from "@mui/material";

import type { SelectFieldProps } from "types";
import { autoComplete, fieldRules } from "utils/field.utils";

const StyledRender = styled(Box)`
  ${({ theme }) => `height: ${theme.spacing(2.5)};`};
  ${({ theme }) => `width: ${theme.spacing(2.5)};`};
  ${({ theme }) => `margin-right: ${theme.spacing(1.25)};`};
  border-radius: 50%;
`;

const GenericSelect: FC<SelectFieldProps> = ({
  value,
  question,
  onChange,
  options,
  color,
  control,
}) => {
  const theme = useTheme();

  const handleChange = (
    e: SelectChangeEvent<string | null>,
    onChangeField: (value: string) => void,
  ) => {
    const selectedValue = e.target.value;
    const selectedOption = options.find(
      (option) => option.name === selectedValue,
    );
    if (selectedValue && selectedOption) {
      onChangeField(selectedValue);
      onChange({ id: selectedOption.id.toString(), value: selectedValue });
    }
  };

  return (
    <Controller
      name={question.id}
      control={control}
      rules={fieldRules(question, useIntl())}
      render={({ field, fieldState }) => {
        autoComplete(options, field, onChange);
        return (
          <FormControl sx={{ width: "100%" }}>
            <InputLabel
              sx={fieldState.error && { color: theme.palette.error.main }}
            >
              {question.fieldConfig?.placeholder}
            </InputLabel>
            <Select
              {...field}
              label={question.fieldConfig?.placeholder}
              value={value || ""}
              error={!!fieldState.error}
              disabled={options.length === 0}
              onChange={(e) => handleChange(e, field.onChange)}
              renderValue={(selectedValue) => {
                const selectedOption = options.find(
                  (option) => option.name === selectedValue,
                );
                return (
                  selectedOption && (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {color && <StyledRender bgcolor={selectedOption.color} />}
                      <Typography>{selectedOption.name}</Typography>
                    </Box>
                  )
                );
              }}
            >
              {options.map((option) => (
                <MenuItem
                  key={option.id}
                  value={option.name}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {color && <StyledRender bgcolor={option.color} />}
                  <Typography>{option.name}</Typography>
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              <Typography variant={"caption"} color={theme.palette.error.main}>
                {fieldState.error?.message}
              </Typography>
            </FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};

export default GenericSelect;
