import * as React from "react";
import type { FC } from "react";
import { Controller } from "react-hook-form";
import { useIntl } from "react-intl";

import {
  FormControl,
  FormGroup,
  FormHelperText,
  Grid,
  Paper,
  Typography,
  alpha,
  styled,
  useTheme,
} from "@mui/material";

import { DealerList, SearchBar } from "./index";
import imageMap from "assets/images/ImageMap.webp";
import type { SelectFieldProps } from "types";
import { fieldRules } from "utils/field.utils";

const StyledGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const DealerLocator: FC<SelectFieldProps> = ({
  value,
  question,
  onChange,
  options,
  control,
}) => {
  const theme = useTheme();
  const formRef = React.useRef<HTMLDivElement>(null);

  return (
    <Controller
      name={question.id}
      control={control}
      rules={fieldRules(question, useIntl())}
      render={({ field, fieldState }) => (
        <FormControl
          sx={{
            mb: 5,
            width: "100%",
          }}
        >
          <FormGroup ref={formRef}>
            <Grid
              container
              mb={2}
              sx={{
                width: "100%",
                height: { md: "50vh", xs: "100%" },
                display: "flex",
                justifyContent: "space-between",
              }}
              tabIndex={0}
              onClick={() => {
                if (formRef.current) {
                  formRef.current.focus();
                }
              }}
              onBlur={() => {
                field.onBlur();
              }}
            >
              <StyledGrid item md={6.5} xs={12}>
                <Paper
                  sx={{
                    width: "100%",
                    height: { md: "100%", xs: "30vh" },
                    backgroundImage: `url(${imageMap})`,
                    backgroundPosition: "center",
                  }}
                ></Paper>
              </StyledGrid>
              <StyledGrid item md={5} xs={12}>
                <Paper
                  sx={{
                    width: "100%",
                    height: { md: "100%", xs: "50vh" },
                    backgroundColor: alpha(theme.palette.secondary.light, 0.85),
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <SearchBar />
                  <DealerList onChange={onChange} field={{ ...field, value }} />
                </Paper>
              </StyledGrid>
            </Grid>
          </FormGroup>
          <FormHelperText>
            <Typography
              variant="body1"
              textAlign="center"
              color={theme.palette.error.main}
            >
              {fieldState.error?.message}
            </Typography>
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default DealerLocator;
