import type { FC } from "react";

import { styled } from "@mui/material";

import { CustomPaper } from "components/paper";

interface Props {
  children: (JSX.Element | undefined | number)[];
}

const StyledPaper = styled(CustomPaper)`
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly;
`;

const SubPaper: FC<Props> = ({ children }) => (
  <StyledPaper sx={{ px: 3, mb: 5 }}>{children}</StyledPaper>
);

export default SubPaper;
