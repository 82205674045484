import React, { type FC } from "react";

import { CircularProgress, styled, useTheme } from "@mui/material";

import { useIsMobile } from "hooks";

interface Props {
  fullHeight?: boolean;
}

const Spinner: FC<Props> = ({ fullHeight }) => {
  const isMobile = useIsMobile();
  const theme = useTheme();

  const StyledSpinnerContainer = styled("div")`
    display: flex;
    ${fullHeight
      ? isMobile
        ? "height: 70vh"
        : "height: 100%"
      : "height: 1vh"};
    justify-content: center;
    align-items: center;
    ${!fullHeight && `margin-left: ${theme.spacing(4)}`};
  `;

  return (
    <StyledSpinnerContainer sx={{ mr: 0.5 }}>
      <CircularProgress size={!fullHeight ? "1.5rem" : undefined} />
    </StyledSpinnerContainer>
  );
};

export default Spinner;
