const colors = {
  yellowLight: "#F8EB4C",
  yellow: "#EFDF00",
  yellowDark: "#CBC021",
  white: "#FFFFFF",
  gray: "#F2F2F2",
  grayLight: "#D9D9D6",
  grayMid: "#BBBCBC",
  grayDark: "#888B8D",
  black: "#000000",
  red: "#D32F2F",
};

export { colors };
