import { createSlice } from "@reduxjs/toolkit";

import { ERROR_MESSAGE, ERROR_SEVERITY } from "types";

type SnackbarState = {
  open: boolean;
  message?: ERROR_MESSAGE;
  variant?: ERROR_SEVERITY;
};

const initialState: SnackbarState = {
  open: false,
};

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    setSnackbarOpen: (
      state,
      {
        payload,
      }: {
        payload: {
          open: boolean;
          message?: ERROR_MESSAGE;
          variant?: ERROR_SEVERITY;
        };
      },
    ) => {
      state.open = payload.open;
      if (payload.message) state.message = payload?.message;
      if (payload.variant) state.variant = payload?.variant;
    },
  },
});

export default snackbarSlice.reducer;
export const { setSnackbarOpen } = snackbarSlice.actions;
