import * as React from "react";
import type { FC } from "react";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Radio, useTheme } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

import { useIsMobile } from "hooks";
import type { DealerListProps } from "types";
import { FakeDealerList } from "utils/hardCoded.utils";

const DealerList: FC<DealerListProps> = ({ onChange, field }) => {
  const theme = useTheme();
  const isMobile = useIsMobile();

  const handleSelectDealer = (dealerBirId: string) => {
    field.onChange(dealerBirId);
    onChange({
      id: "bir_id",
      value: dealerBirId,
    });
  };

  return (
    <List sx={{ overflowY: "scroll", p: 0 }}>
      {FakeDealerList.map((dealer) => (
        <>
          <ListItem
            alignItems="center"
            sx={{
              backgroundColor:
                field.value === dealer.birId
                  ? theme.palette.secondary.dark
                  : "transparent",
              "&:hover": {
                backgroundColor:
                  field.value === dealer.birId
                    ? theme.palette.secondary.dark
                    : theme.palette.secondary.main,
              },
            }}
            onClick={() => handleSelectDealer(dealer.birId)}
          >
            {!isMobile && (
              <ListItemAvatar>
                <LocationOnIcon
                  sx={{ color: theme.palette.primary.dark, fontSize: 40 }}
                />
              </ListItemAvatar>
            )}
            <ListItemText
              primary={dealer.name.toUpperCase()}
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    sx={{ color: "text.primary", display: "inline" }}
                  >
                    {dealer.address}
                  </Typography>
                </React.Fragment>
              }
            />
            <Radio
              checked={field.value === dealer.birId}
              onChange={(e) => {
                e.stopPropagation();
                handleSelectDealer(dealer.birId);
              }}
            />
          </ListItem>
          {FakeDealerList[FakeDealerList.indexOf(dealer) + 1] && <Divider />}
        </>
      ))}
    </List>
  );
};

export default DealerList;
