import React, { type FC } from "react";

import { Typography, TypographyProps, useTheme } from "@mui/material";

interface Props extends TypographyProps {}

const StyledTitle: FC<Props> = ({ children, ...typographyProps }) => {
  const theme = useTheme();

  return (
    <Typography
      my={2}
      ml={2}
      pl={2}
      fontWeight="bold"
      display="flex"
      sx={{
        borderLeft: "solid 4px",
        borderColor: theme.palette.primary.main,
      }}
      {...typographyProps}
    >
      {children}
    </Typography>
  );
};

export default StyledTitle;
