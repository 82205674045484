import React, { type FC, useEffect } from "react";

import { AppRouter } from "./routers";
import { setConfiguration } from "./store/common/common.reducer";
import { useAppDispatch } from "./store/hooks";
import { loadConfiguration } from "env/env";
import useSnackbar from "hooks/useSnackbar";

const App: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      try {
        dispatch(setConfiguration(await loadConfiguration()));
      } catch (e) {
        console.error("Failed to load conf file");
      }
    })();
  }, []);

  useSnackbar();
  return <AppRouter />;
};

export default App;
