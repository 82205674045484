import common from "./common.json";
import footer from "./footer.json";
import funnel from "./funnel.json";
import messages from "./messages.json";
import routes from "./routes.json";

const fr = {
  ...common,
  ...routes,
  ...footer,
  ...messages,
  ...funnel,
};

export default fr;
