import React, { type FC } from "react";
import { useIntl } from "react-intl";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import SpeedIcon from "@mui/icons-material/Speed";
import { Box, Divider, Stack, Typography, styled } from "@mui/material";

import { CustomPaper } from "components/paper";
import dayjs from "dayjs";
import { useAppSelector } from "store/hooks";
import type { QuotationDTO } from "types";
import { FIRST_DATE_FORMAT, formatDistance } from "utils";

interface Props {
  quotation: QuotationDTO | undefined;
}

const VehicleBlock: FC<Props> = ({ quotation }) => {
  const intl = useIntl();

  const { formInit } = useAppSelector((state) => state.funnel);

  const StyledStack = styled(Stack)`
    width: 90%;
    max-width: 100%;
    justify-content: space-evenly;
  `;

  const StyledPaper = styled(CustomPaper)`
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `;

  const items = [
    {
      value: dayjs(quotation?.firstRegistrationDate)
        .format(FIRST_DATE_FORMAT)
        .toString(),
      icon: <CalendarMonthIcon />,
    },
    ...(quotation?.energy && formInit
      ? [
          {
            value: formatDistance(
              intl,
              quotation.mileage,
              formInit.distanceUnit,
            ),
            icon: <SpeedIcon />,
          },
        ]
      : []),
    ...(quotation?.energy
      ? [
          {
            value: quotation.energy,
            icon: <LocalGasStationIcon />,
          },
        ]
      : []),
  ];

  return (
    <StyledPaper sx={{ pb: 2 }}>
      <Box component="img" src={quotation?.image} width={"70%"} />
      <StyledStack
        direction={"row" as const}
        divider={<Divider orientation={"vertical"} flexItem />}
        spacing={2}
        px={2}
        py={0}
      >
        {items.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {item.icon}
            <Typography textAlign="center">{item.value}</Typography>
          </Box>
        ))}
      </StyledStack>
    </StyledPaper>
  );
};

export default VehicleBlock;
