import type { ControllerRenderProps } from "react-hook-form";
import { type IntlShape } from "react-intl";

import { TextFieldProps } from "@mui/material/TextField/TextField";

import type { OptionDTO, QuestionAnswer, QuestionDTO, Rules } from "types";

export const questionDTOToInputProps = (
  question: QuestionDTO,
): TextFieldProps => {
  const { fieldConfig } = question;
  return {
    placeholder: fieldConfig?.placeholder,
    InputProps: {
      inputProps: {
        max: fieldConfig?.max,
        min: fieldConfig?.min,
      },
    },
  };
};

export const fieldRules = (question: QuestionDTO, intl: IntlShape) => {
  const regex = question.fieldConfig?.regex
    ? new RegExp(question.fieldConfig.regex)
    : undefined;

  const rules: Rules = {
    required: {
      value: question.mandatory,
      message: intl.formatMessage({ id: "funnel.input.required" }),
    },
  };

  if (regex) {
    rules.pattern = {
      value: regex,
      message: intl.formatMessage({ id: "funnel.input.regex" }),
    };
  }

  if (question.fieldConfig?.min !== undefined) {
    rules.min = {
      value: question.fieldConfig.min,
      message: intl.formatMessage(
        {
          id: "funnel.input.min",
        },
        { min: question.fieldConfig.min },
      ),
    };
  }

  if (question.fieldConfig?.max !== undefined) {
    rules.max = {
      value: question.fieldConfig.max,
      message: intl.formatMessage(
        {
          id: "funnel.input.min",
        },
        { max: question.fieldConfig.max },
      ),
    };
  }

  return rules;
};

export const autoComplete = (
  options: OptionDTO[],
  field: ControllerRenderProps,
  onChange: (arg0: QuestionAnswer) => void,
) => {
  if (options.length === 1 && !field.value) {
    const defaultOption = options[0];
    onChange({ id: defaultOption.id, value: defaultOption.name });
    field.onChange(defaultOption.name);
  }
};
