import {
  type Middleware,
  type MiddlewareAPI,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/src/query/fetchBaseQuery";

import { type SnackbarProps, addSnackbar } from "store/common/common.reducer";
import { ERROR_MESSAGE } from "types";
import { getErrorFromFetch } from "utils";

const rtkQueryErrorLogger: Middleware =
  ({ dispatch }: MiddlewareAPI) =>
  (next) =>
  (action) => {
    const displayError = (props: Omit<SnackbarProps, "severity">) =>
      dispatch(addSnackbar({ ...props, severity: "error" }));

    if (isRejectedWithValue(action)) {
      if ((action.payload as FetchBaseQueryError).status !== 404) {
        const error = getErrorFromFetch(
          (action.payload as FetchBaseQueryError).data,
        );

        if (error?.message) {
          displayError({
            message: error.message,
          });
        } else {
          displayError({
            message: `messages.error.${ERROR_MESSAGE.DEFAULT_ERROR}`,
            translation: true,
          });
        }
      }
    }
    return next(action);
  };

export default rtkQueryErrorLogger;
