import { combineReducers, configureStore } from "@reduxjs/toolkit";

import api from "api";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import common from "store/common/common.reducer";
import funnel from "store/funnel/funnel.reducer";
import middlewares from "store/middlewares";

const reducers = combineReducers({
  [api.reducerPath]: api.reducer,
  common,
  funnel,
});

const persistConfig = { key: "root", storage };

const store = configureStore({
  reducer: persistReducer(persistConfig, reducers),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
});

export type RootState = ReturnType<typeof reducers>;
export type AppDispatch = typeof store.dispatch;

export default store;
