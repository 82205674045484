export const DEFAULT_URL = "https://cote.renault.fr";
export const RENAULT_WEBSITE_URL = "https://www.renault.fr/";
export const RENEW_URL = "https://fr.renew.auto/";
export const RENAULT_MODELS_URL =
  "https://cote.renault.fr/modeles-renault.html";
export const RENAULT_FAQ_URL =
  "https://www.renault.fr/cote-voiture-gratuite.html";
export const RENAULT_YOUR_DATA_URL =
  "https://www.renault.fr/donnees-personnelles.html";
export const RENAULT_LEGAL_INFORMATION_URL =
  "https://www.renault.fr/mentions-legales.html";
export const RENAULT_COOKIES_URL = "https://www.renault.fr/cookies.html";

export const getLocaleFromUrl = (env?: string) => {
  const url = env === "DEV" ? DEFAULT_URL : window.location.origin;
  const ccTLD = url.substring(url.length - 2);
  return `${ccTLD}_${ccTLD.toUpperCase()}`;
};
