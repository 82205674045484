import React from "react";
import { useIntl } from "react-intl";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Box } from "@mui/material";

import AppFooter from "components/footer";
import AppHeader from "components/header";
import { routes } from "utils/navigation.utils";

const AppRouter: React.FunctionComponent = () => {
  const intl = useIntl();

  return (
    <BrowserRouter>
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <AppHeader />
        <Routes>
          {routes.map((route) => (
            <Route
              key={route.path}
              path={intl.formatMessage({ id: route.path })}
              Component={route.element}
            />
          ))}
        </Routes>
        <AppFooter />
      </Box>
    </BrowserRouter>
  );
};

export default AppRouter;
