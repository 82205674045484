import { createTheme } from "@mui/material";

import { breakpoints, colors } from "assets/styles";
import { fonts } from "assets/typography";

const buttonHover = {
  transition: "0.3s",
  transitionTimingFunction: "ease-out",
  backgroundColor: colors.yellow,
  color: colors.black,
};

export const theme = createTheme({
  shape: {
    borderRadius: 0,
  },
  spacing: 8,
  breakpoints: {
    values: {
      xs: breakpoints.xs,
      sm: breakpoints.sm,
      md: breakpoints.md,
      lg: breakpoints.lg,
      xl: breakpoints.xl,
    },
  },
  palette: {
    primary: {
      main: colors.yellow,
      light: colors.yellowLight,
      dark: colors.yellowDark,
      contrastText: colors.black,
    },
    secondary: {
      main: colors.grayMid,
      light: colors.grayLight,
      dark: colors.grayDark,
      contrastText: colors.black,
    },
    common: {
      white: colors.white,
      black: colors.black,
    },
    tonalOffset: 0.05,
  },
  typography: {
    fontFamily: [
      fonts.nouvelR,
      "-apple-system",
      '"Segoe UI"',
      "Roboto",
      "Arial",
      "sans-serif",
    ].join(","),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          display: "flex",
          marginBottom: "16px",
          boxShadow: "none !important",
          borderRadius: 0,
          borderWidth: "4px",
          transition: "0.3s",
          fontFamily: fonts.nouvelRBold,
          width: "400px",
          justifySelf: "center",
          border: "solid 1px",
          borderColor: colors.black,
          "&.MuiButton-colorPrimary": {
            backgroundColor: colors.black,
            color: colors.white,
            "&:hover": buttonHover,
          },
          "&.MuiButton-colorSecondary": {
            backgroundColor: colors.white,
            color: colors.black,
            "&:hover": buttonHover,
          },
          "&.Mui-disabled": {
            backgroundColor: colors.grayMid,
            color: colors.black,
            border: "transparent",
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: colors.black,
          textDecorationColor: colors.black,
          textDecorationThickness: 0.2,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          color: colors.black,
          light: true,
          textDecorationColor: colors.black,
          fontFamily: fonts.nouvelRBold,
          borderRightWidth: 0.5,
          "&::before, &::after": {
            borderColor: colors.grayMid,
            borderWidth: "2px",
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          anchorOrigin: { horizontal: "center", vertical: "bottom" },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          width: "100%",
          borderRadius: 0,
          fontSize: 16,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: "400px",
          boxShadow: "none !important",
          borderRadius: 0,
          borderWidth: 0,
          backgroundColor: colors.white,
          marginBottom: "16px",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: "16px",
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: "28px",
          height: "16px",
          padding: 0,
          margin: "8px",
          display: "flex",
          "&:active": {
            "& .MuiSwitch-thumb": {
              width: "12px",
            },
          },
          "& .MuiSwitch-switchBase": {
            padding: 2,
            "&.Mui-checked": {
              transform: "translateX(12px)",
              color: colors.white,
              "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: colors.yellow,
              },
            },
          },
          "& .MuiSwitch-thumb": {
            width: "12px",
            height: "12px",
          },
          "& .MuiSwitch-track": {
            borderRadius: "32px",
            opacity: 1,
            backgroundColor: colors.black,
            boxSizing: "border-box",
          },
        },
      },
    },
  },
});
