import type { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import {
  type BaseQueryFn,
  FetchArgs,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

import endpoints from "./endpoints";
import type { RootState } from "store/store";
import type {
  FunnelConfigurationDTO,
  FunnelDTO,
  QuestionNextStepDTO,
  QuestionRequest,
  QuotationDTO,
  QuotationParameters,
  StepRequest,
} from "types";

const selectCommonState = (state: RootState) => state.common;

const rawBaseQuery = fetchBaseQuery({
  prepareHeaders(headers) {
    headers.set("x-agent", "WEB");
  },
  timeout: 60000,
  credentials: "include",
  redirect: "manual",
});

const dynamicBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const state = selectCommonState(api.getState() as RootState);
  const baseUrl = state.config?.apiBaseHostname;
  const urlEnd = typeof args === "string" ? args : args.url;
  const adjustedUrl = `${baseUrl}${urlEnd}`;
  const adjustedArgs =
    typeof args === "string" ? adjustedUrl : { ...args, url: adjustedUrl };
  return rawBaseQuery(adjustedArgs, api, extraOptions);
};

const api = createApi({
  reducerPath: "api",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    getFunnels: builder.query<FunnelDTO[], string>({
      query: (locale) => ({
        url: endpoints.funnels.get,
        method: "GET",
        params: { locale },
      }),
    }),
    getFunnelConfigurations: builder.query<
      FunnelConfigurationDTO,
      QuestionRequest
    >({
      query: (body) => {
        return {
          url: endpoints.funnelConfigurations.get,
          method: "POST",
          body,
        };
      },
    }),
    getNextQuestion: builder.query<QuestionNextStepDTO[], StepRequest>({
      query: (body) => {
        return {
          url: endpoints.nextQuestion.get,
          method: "POST",
          body,
        };
      },
    }),
    getQuotation: builder.query<QuotationDTO, QuotationParameters>({
      query: (body) => {
        return {
          url: endpoints.quotation.get,
          method: "POST",
          body,
        };
      },
    }),
  }),
});

export const {
  useGetFunnelsQuery,
  useLazyGetFunnelConfigurationsQuery,
  useLazyGetNextQuestionQuery,
  useLazyGetQuotationQuery,
} = api;

export default api;
