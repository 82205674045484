export const URL_ARRAY = [
  "https://www.renault.fr/agg/vn/unique/ONE_DACIA_PP_LARGE_DENSITY1/r_brandSite_carPicker_1.png?uri=https%3A%2F%2Ffr.co.rplug.renault.com%2Fproduct%2Fmodel%2F2W3%2Ftwingo%2Fc%2FA-ENS_0MDL2P1SERIELIM2_-OVRPP",
  "https://www.renault.fr/agg/vn/unique/ONE_DACIA_PP_LARGE_DENSITY1/r_brandSite_carPicker_1.png?uri=https%3A%2F%2Ffr.co.rplug.renault.com%2Fproduct%2Fmodel%2FTWZ%3Bck%3DVP%2Ftwizy-e-tech-electrique%2Fc%2FA-ENS_0MDL2P1SERIELIM3_-TEGNE",
  "https://www.renault.fr/agg/vn/unique/ONE_DACIA_PP_LARGE_DENSITY1/r_brandSite_carPicker_1.png?uri=https%3A%2F%2Ffr.co.rplug.renault.com%2Fproduct%2Fmodel%2FTRP%2Fnouveau-trafic-vp%2Fc%2FA-ENS_0MDL2P1SERIELIM3_-TED68",
];

export const FakeDealerList = [
  {
    name: "Renault Alma",
    address: "14 rue Auber - 75009 Paris",
    birId: "00000703",
    lat: "48.873070",
    lon: "2.328340",
  },
  {
    name: "Ancenis",
    address: "14 rue Auber - 75009 Paris",
    birId: "00000704",
    lat: "48.873070",
    lon: "2.328340",
  },
  {
    name: "La Roche Sur Yon",
    address: "14 rue Auber - 75009 Paris",
    birId: "00000705",
    lat: "48.873070",
    lon: "2.328340",
  },
  {
    name: "Lannion",
    address: "14 rue Auber - 75009 Paris",
    birId: "00000706",
    lat: "48.873070",
    lon: "2.328340",
  },
  {
    name: "Lorient",
    address: "14 rue Auber - 75009 Paris",
    birId: "00000707",
    lat: "48.873070",
    lon: "2.328340",
  },
  {
    name: "Concessionaire Test 5",
    address: "14 rue Auber - 75009 Paris",
    birId: "00000708",
    lat: "48.873070",
    lon: "2.328340",
  },
  {
    name: "Concessionaire Test 6",
    address: "14 rue Auber - 75009 Paris",
    birId: "00000709",
    lat: "48.873070",
    lon: "2.328340",
  },
  {
    name: "Concessionaire Test 7",
    address: "14 rue Auber - 75009 Paris",
    birId: "00000710",
    lat: "48.873070",
    lon: "2.328340",
  },
  {
    name: "Concessionaire Test 8",
    address: "14 rue Auber - 75009 Paris",
    birId: "00000711",
    lat: "48.873070",
    lon: "2.328340",
  },
  {
    name: "Concessionaire Test 9",
    address: "14 rue Auber - 75009 Paris",
    birId: "00000712",
    lat: "48.873070",
    lon: "2.328340",
  },
  {
    name: "Concessionaire Test 10",
    address: "14 rue Auber - 75009 Paris",
    birId: "00000713",
    lat: "48.873070",
    lon: "2.328340",
  },
  {
    name: "Concessionaire Test 11",
    address: "14 rue Auber - 75009 Paris",
    birId: "00000714",
    lat: "48.873070",
    lon: "2.328340",
  },
  {
    name: "Concessionaire Test 12",
    address: "14 rue Auber - 75009 Paris",
    birId: "00000715",
    lat: "48.873070",
    lon: "2.328340",
  },
];
