import { IntlShape } from "react-intl";

export const checkPropertiesOf = <T extends {}>(
  keys: Array<string>,
  element?: any,
): element is T =>
  element !== null &&
  typeof element === "object" &&
  keys.every((key) => key in element);

export const formatDistance = (
  intl: IntlShape,
  value: number,
  unit: string,
): string =>
  intl.formatNumber(value, {
    style: "unit",
    maximumFractionDigits: 0,
    unit,
    unitDisplay: "short",
  });

export const formatPrice = (
  intl: IntlShape,
  value: number,
  currency: string,
): string =>
  intl.formatNumber(value, {
    style: "currency",
    maximumFractionDigits: 0,
    currency,
  });
