import React from "react";

import { Typography, useTheme } from "@mui/material";

const MandatoryAsterisk = () => {
  const theme = useTheme();
  return <Typography color={theme.palette.error.main}>{" *"}</Typography>;
};

export default MandatoryAsterisk;
