import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { useGetFunnelsQuery } from "api/api";
import { FunnelLayout } from "layouts";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setSnackbarOpen } from "store/snackbar";
import { ERROR_MESSAGE, ERROR_SEVERITY, type FUNNEL_TYPE } from "types";
import { getAllowedFunnels, getLocaleFromUrl } from "utils";

const Home: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const { config } = useAppSelector((state) => state.common);
  const { data: funnels } = useGetFunnelsQuery(getLocaleFromUrl(config?.env));
  const [allowedFunnels, setAllowedFunnels] = useState<FUNNEL_TYPE[]>([]);

  useEffect(() => {
    if (funnels) {
      let localeFunnels = getAllowedFunnels(funnels);
      setAllowedFunnels(localeFunnels);
      if (localeFunnels.length === 0) {
        dispatch(
          setSnackbarOpen({
            open: true,
            message: ERROR_MESSAGE.NOT_FOUND,
            variant: ERROR_SEVERITY.ERROR,
          }),
        );
      }
    }
  }, [funnels]);

  return (
    <FunnelLayout
      title={intl.formatMessage({ id: "funnel.type.title" }).toUpperCase()}
      enumActions={allowedFunnels}
    />
  );
};

export default Home;
