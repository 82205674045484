import React from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { AppBar, styled, useTheme } from "@mui/material";

import { fonts } from "assets/typography";

const HeaderTitle = styled("h3")`
  ${({ theme }) => `font-size: ${theme.spacing(2)};`};
  font-family: ${fonts.nouvelRBold};
  ${({ theme }) => `padding-left: ${theme.spacing(5)};`};
  align-self: center;
  ${({ theme }) => `color: ${theme.palette.common.white};`};
`;

const HeaderContainer = styled("header")`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  ${({ theme }) => `padding: ${theme.spacing(4)};`};
  ${({ theme }) => `background-color: ${theme.palette.common.black};`};
`;

const AppHeader: React.FunctionComponent = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const theme = useTheme();

  const handleClick = () => {
    navigate("/");
  };

  return (
    <AppBar position="static" elevation={0}>
      <HeaderContainer theme={theme}>
        <img
          src={require("assets/images/renault-white.png")}
          onClick={handleClick}
          alt="logo"
          width={60}
          height={80}
          style={{ cursor: "pointer" }}
        />
        <HeaderTitle theme={theme}>
          {intl.formatMessage({ id: "header.title" }).toUpperCase()}
        </HeaderTitle>
      </HeaderContainer>
    </AppBar>
  );
};

export default AppHeader;
