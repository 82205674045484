import React from "react";
import ReactDOM from "react-dom/client";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";

import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import App from "./App";
import config, {
  DEFAULT_LANGUAGE,
  LOCALE_STORAGE_COUNTRY,
} from "./setUpLanguage";
import { theme } from "./themes";
import "assets/styles/common.css";
import { SnackbarProvider } from "notistack";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import store from "store/store";

const persistor = persistStore(store);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          <IntlProvider
            locale={
              localStorage.getItem(LOCALE_STORAGE_COUNTRY) || DEFAULT_LANGUAGE
            }
            messages={config.intl}
            defaultLocale={DEFAULT_LANGUAGE}
          >
            <SnackbarProvider maxSnack={5}>
              <App />
            </SnackbarProvider>
          </IntlProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </PersistGate>
  </Provider>,
);
