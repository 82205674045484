import { styled } from "@mui/material";

import background from "assets/images/quotation-background.webp";

export const MainContainer = styled("main")`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  vertical-align: baseline;
  border-width: 0;
  flex-grow: 1;
  background-size: cover;
  object-fit: cover;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(${background});
`;
