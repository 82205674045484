import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import SearchIcon from "@mui/icons-material/Search";
import { Button, Grid, alpha, useTheme } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import InputBase from "@mui/material/InputBase";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";

import { colors } from "assets/styles";
import { useIsMobile } from "hooks";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: "100%",
  position: "absolute",
  display: "flex",
  alignItems: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    transition: theme.transitions.create("width"),
  },
}));

const SearchBar = () => {
  const theme = useTheme();
  const intl = useIntl();
  const isMobile = useIsMobile();

  return (
    <AppBar position="static" sx={{ backgroundColor: colors.black }}>
      <Toolbar>
        <Grid
          container
          spacing={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item lg={9} xs={8} height="100%">
            <Search>
              {!isMobile && (
                <SearchIconWrapper>
                  <SearchIcon sx={{ color: colors.black, zIndex: 1 }} />
                </SearchIconWrapper>
              )}
              <StyledInputBase
                placeholder={intl.formatMessage({
                  id: "funnel.input.search",
                })}
                inputProps={{ "aria-label": "search" }}
                sx={{
                  width: "100%",
                  pl: theme.spacing(isMobile ? 1 : 5),
                  backgroundColor: colors.white,
                }}
              />
            </Search>
          </Grid>
          <Grid item lg={3} xs={4}>
            <Button
              color="secondary"
              sx={{
                width: "100%",
                fontSize: { sm: "", xs: 12 },
                m: 0,
                backgroundColor: `${colors.yellow} !important`,
                color: colors.black,
                "&:hover": {
                  backgroundColor: `${colors.yellowDark} !important`,
                },
              }}
            >
              <FormattedMessage id="funnel.form.search" />
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default SearchBar;
