export default {
  funnels: {
    get: "/funnels",
  },
  funnelConfigurations: {
    get: "/questions",
  },
  nextQuestion: {
    get: "/steps",
  },
  quotation: {
    get: "/quotation",
  },
};
