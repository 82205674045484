import React, { type FC } from "react";

import { Button } from "@mui/material";

interface Props {
  message: JSX.Element;
  primary?: boolean;
}

const ContactButton: FC<Props> = ({ message, primary }) => (
  <Button
    color={primary ? "primary" : "secondary"}
    sx={{
      m: 0.5,
      width: "100%",
    }}
  >
    {message}
  </Button>
);

export default ContactButton;
