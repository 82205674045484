export const DATE_FORMAT = "DD-MM-YYYY";
export const FIRST_DATE_FORMAT = "MM/YYYY";
export const VALIDITY_DATE_FORMAT = "DD/MM/YYYY";
export const REGEX_PLATE = /^[a-zA-Z]{2}-?\d{3}-?[a-zA-Z]{2}$/;
export const COMPLETION_STATE = [
  {
    id: "19",
    answers: [
      {
        id: "model_of_interest_type",
        value: "VN",
      },
      {
        id: "model_1",
        value: "twingo-b07-ph2",
      },
    ],
  },
  {
    id: "5",
    answers: [
      {
        id: "firstname",
        value: "Test",
      },
      {
        id: "lastname",
        value: "CI",
      },
      {
        id: "email",
        value: "test-ci@gmail.com",
      },
      {
        id: "title",
        value: "Mr",
      },
    ],
  },
];
