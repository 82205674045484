import React, { type FC } from "react";

import { Grid } from "@mui/material";

import { OfferBlock } from "./index";
import type { QuotationDTO } from "types";

interface Props {
  quotation: QuotationDTO | undefined;
}

const OfferArea: FC<Props> = ({ quotation }) => {
  const promotionNameArray = quotation?.promotions.map((e) => e.model.name);

  return (
    <Grid container spacing={2} mb={4}>
      {promotionNameArray?.map((elem, index) => (
        <Grid item md={4} xs={12}>
          <OfferBlock name={elem} index={index} />
        </Grid>
      ))}
    </Grid>
  );
};

export default OfferArea;
