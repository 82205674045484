import React, { type FC, useState } from "react";

import { Box, Typography, styled, useTheme } from "@mui/material";

import { URL_ARRAY } from "utils/hardCoded.utils";

interface Props {
  name: string;
  index: number;
}

const StyledOverlay = styled("div", {
  shouldForwardProp: (prop) => prop !== "isHovering",
})<{ isHovering?: boolean }>(({ theme, isHovering }) => ({
  position: "absolute",
  backgroundColor: theme.palette.primary.contrastText,
  ...(isHovering ? { opacity: 0.6 } : { opacity: 0.4 }),
  height: "100%",
  width: "100%",
  cursor: "pointer",
}));

const OfferBlock: FC<Props> = ({ name, index }) => {
  const theme = useTheme();

  const [isHovering, setIsHovering] = useState(false);

  return (
    <Box
      sx={{ width: "100%", position: "relative" }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <StyledOverlay isHovering={isHovering} />
      <Box
        component="img"
        src={URL_ARRAY[index]}
        style={{
          opacity: 1,
          width: "100%",
          transition: ".5s ease",
          display: "block",
        }}
      />
      <Typography
        sx={{
          color: "white",
          position: "absolute",
          ...(isHovering ? { left: 24 } : { left: 16 }),
          bottom: 16,
          fontWeight: "bold",
          paddingLeft: 1,
          borderLeft: "4px solid",
          borderLeftColor: theme.palette.primary.main,
          transition: "all 200ms ease-in",
        }}
      >
        {name}
      </Typography>
    </Box>
  );
};

export default OfferBlock;
