export enum CONTEXT {
  RENAULT = "RENAULT",
  DACIA = "DACIA",
  NONE = "NONE",
}

export enum DAMAGE_LEVEL {
  GOOD_CONDITION = "GOOD_CONDITION",
  AVERAGE_CONDITION = "AVERAGE_CONDITION",
  BAD_CONDITION = "BAD_CONDITION",
}

export enum ERROR_CODE {
  F1 = "F1",
  F2 = "F2",
  F3 = "F3",
  F4 = "F4",
  F5 = "F5",
  F6 = "F6",
  F7 = "F7",
  F8 = "F8",
  F9 = "F9",
  F10 = "F10",
  F11 = "F11",
  F12 = "F12",
  F13 = "F13",
  F14 = "F14",
  P1 = "P1",
  P2 = "P2",
  P3 = "P3",
  P4 = "P4",
  P5 = "P5",
  P6 = "P6",
  P7 = "P7",
  C1 = "C1",
  C2 = "C2",
  C3 = "C3",
  C4 = "C4",
  C5 = "C5",
  C6 = "C6",
}

export enum ERROR_MESSAGE {
  INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
  NOT_FOUND = "NOT_FOUND",
  DEFAULT_ERROR = "DEFAULT_ERROR",
}

export enum ERROR_SEVERITY {
  SUCCESS = "SUCCESS",
  INFO = "INFO",
  WARNING = "WARNING",
  ERROR = "ERROR",
}

export enum ERROR_SOURCE {
  FRONT = "FRONT",
  PROVIDER = "PROVIDER",
  CONFIG = "CONFIG",
}

export enum ERROR_TYPE {
  TECHNICAL = "TECHNICAL",
  FUNCTIONAL = "FUNCTIONAL",
}

export enum FUNNEL_COMPONENT_TYPE {
  BUTTON = "BUTTON",
  INPUT = "INPUT",
}

export enum FUNNEL_METHOD {
  WITH_REGISTRATION_PLATE = "WITH_REGISTRATION_PLATE",
  WITHOUT_REGISTRATION_PLATE = "WITHOUT_REGISTRATION_PLATE",
}

export enum FUNNEL_TYPE {
  SHORT = "SHORT",
  LONG = "LONG",
}

export enum PROFILE {
  PRO = "PRO",
  PRIVATE = "PRIVATE",
}

export enum QUESTION_TYPE {
  INPUT_TEXT = "INPUT_TEXT",
  INPUT_NUMBER = "INPUT_NUMBER",
  SELECT = "SELECT",
  RADIO = "RADIO",
  MULTI_SELECT = "MULTI_SELECT",
  COLOR_SELECT = "COLOR_SELECT",
  CHECKBOX = "CHECKBOX",
  PERSONAL_INFO = "PERSONAL_INFO",
  DEALER_LOCATOR = "DEALER_LOCATOR",
  MODEL_OF_INTEREST = "MODEL_OF_INTEREST",
  DAMAGES_FRONT = "DAMAGES_FRONT",
  DAMAGES_BACK = "DAMAGES_BACK",
  DAMAGES_LEFT = "DAMAGES_LEFT",
  DAMAGES_RIGHT = "DAMAGES_RIGHT",
  DAMAGES_INTERIOR = "DAMAGES_INTERIOR",
}

export enum VEHICLE_TYPE {
  VO = "VO",
  VN = "VN",
  NONE = "NONE",
}
