import { fonts } from "assets/typography";
import { theme } from "themes";
import {
  type ActionProps,
  FUNNEL_COMPONENT_TYPE,
  FUNNEL_METHOD,
  FUNNEL_TYPE,
  type FunnelDTO,
  PROFILE,
  type QuestionDTO,
  type SectionDTO,
} from "types";

export const actions: ActionProps[] = [
  {
    actionType: FUNNEL_TYPE.SHORT,
    componentType: FUNNEL_COMPONENT_TYPE.BUTTON,
    label: "funnel.type.short.button",
    subtitle: "funnel.type.short.subtitle",
    redirectPath: "routes.funnel.method",
    componentProps: {
      variant: "contained",
    },
  },
  {
    actionType: FUNNEL_TYPE.LONG,
    componentType: FUNNEL_COMPONENT_TYPE.BUTTON,
    label: "funnel.type.long.button",
    subtitle: "funnel.type.long.subtitle",
    redirectPath: "routes.funnel.method",
    componentProps: {
      variant: "contained",
      sx: {
        color: theme.palette.common.black,
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
          backgroundColor: theme.palette.primary.light,
          transition: "0.3s",
          transitionTimingFunction: "ease-out",
        },
      },
    },
  },
  {
    actionType: FUNNEL_METHOD.WITH_REGISTRATION_PLATE,
    componentType: FUNNEL_COMPONENT_TYPE.INPUT,
    label: "funnel.method.with.plate.input.button",
    subtitle: "funnel.method.with.plate.subtitle",
    redirectPath: "routes.identification",
    placeholder: "funnel.method.with.plate.input.placeholder",
    componentProps: {
      id: "plate",
      inputProps: {
        sx: {
          padding: 0,
          textTransform: "uppercase",
          fontFamily: fonts.nouvelRBold,
          textAlign: "center",
          "&::placeholder": {
            color: theme.palette.secondary.dark,
            fontFamily: fonts.nouvelRBold,
            opacity: 0.9,
            padding: theme.spacing(2, 1, 2, 1),
            textAlign: "center",
          },
        },
      },
    },
  },
  {
    actionType: FUNNEL_METHOD.WITHOUT_REGISTRATION_PLATE,
    componentType: FUNNEL_COMPONENT_TYPE.BUTTON,
    label: "funnel.method.without.plate.button",
    subtitle: "funnel.method.without.plate.subtitle",
    redirectPath: "routes.identification",
    componentProps: {
      variant: "contained",
    },
  },
];

export const getAllowedFunnels = (funnels: FunnelDTO[]): FUNNEL_TYPE[] =>
  funnels?.map((funnel) => funnel.funnelType) || [];

export const getAllowedProfiles = (
  funnels: FunnelDTO[],
  userFunnelType: FUNNEL_TYPE,
): PROFILE[] =>
  funnels
    ?.find((funnel) => funnel.funnelType === userFunnelType)
    ?.profiles.map((value) => value.profile) || [];

export const getAllowedMethods = (
  funnels: FunnelDTO[],
  userFunnelType: FUNNEL_TYPE,
  userProfile: PROFILE,
): FUNNEL_METHOD[] =>
  funnels
    ?.find((funnel) => funnel.funnelType === userFunnelType)
    ?.profiles?.find(
      (funnelProfileDTO) => funnelProfileDTO.profile === userProfile,
    )?.funnelMethods || [];

export const getQuestionById = (
  id: string,
  form: any,
): QuestionDTO | undefined => {
  return form.sections.reduce(
    (foundQuestion: QuestionDTO, section: SectionDTO) => {
      if (foundQuestion) return foundQuestion;
      return section.questions.reduce(
        (question: QuestionDTO | undefined, q) => {
          if (question) return question;
          return q.id === id ? q : undefined;
        },
        undefined,
      );
    },
    undefined,
  );
};
