import FormLayout from "screens/formLayout";
import FourZeroFour from "screens/fourZeroFour";
import FunnelMethod from "screens/funnelMethod";
import Home from "screens/home";
import Quotation from "screens/quotation";
import type { RouteModel } from "types/common.types";

export const routes: RouteModel[] = [
  {
    path: "routes.four.zero.four",
    element: FourZeroFour,
  },
  {
    path: "routes.home",
    element: Home,
  },
  {
    path: "routes.funnel.method",
    element: FunnelMethod,
  },
  {
    path: "routes.identification",
    element: FormLayout,
  },
  {
    path: "routes.quotation",
    element: Quotation,
  },
];
